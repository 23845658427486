<template>
  <div class="knowledge-detail-page common-detail-page">
    <Nav/>
    <div class="top-img">
      <img :src="detail.cover_image" alt="" />
    </div>
    <div class="common-card-wrap">
      <h3>{{ detail.title }}</h3>
      <p class="date">{{ formatDate(detail.create_time) }}</p>
      <div class="v-html-content" v-html="detail.content"></div>
    </div>
    <div class="empty"></div>
  </div>
</template>
<script>
import request from '../../utils/request';
import Nav from "../../component/nav.vue";
import dayjs from 'dayjs';
export default {
  name: "KnowledgeDetail",
  components: {
    Nav
  },
  data() {
    return {
      detail: {},
    };
  },
  methods: {
    formatDate(date) {
      return dayjs(date).format('YYYY.MM.DD HH:mm');
    },
    // 获取新闻列表数据
    fetchInfo(id) {
      request.get('/knowledge/'+id) // 假设接口地址为 /news
        .then(response => {
          this.detail = response; // 将接口返回的数据赋值给newsList
          document.title = this.detail.title;
        })
        .catch(error => {
          console.error('Error fetching news:', error);
        });
    },
  },
  created() { 
    const id = this.$route.query.id;
    // 在组件创建时调用fetchNewsList方法获取新闻列表数据
    this.fetchInfo(id);
  },
};
</script>
<style lang="less" scoped>
.knowledge-detail-page {
  .empty {
    width: 100%;
    height: 58px;
  }
}
</style>
